import React, { useState, useRef, useMemo } from "react";
import { postBlog } from "../Service/blogService";
import JoditEditor from "jodit-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PostBlog = ({ placeholder }) => {
  const [title, setTitle] = useState("");
  const [keywordUrl, setKeywordUrl] = useState("");
  const [category, setCategory] = useState("");
  const [imgAlt, setImgAlt] = useState("");
  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");
  const [metaTitle, setmetaTitle] = useState("");
  const [metaDescription, setmetaDescription] = useState("");
  const [metaKeywords, setmetaKeywords] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      toolbarSticky: false,
      placeholder: placeholder || "Start typing...",
      buttons: [
        {
          name: "paragraph",
          list: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
        },
      ],
    }),
    [placeholder]
  );
  const handleChangeContent = (newContent) => {
    setContent(newContent);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!token) {
      navigate(`/login`);
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("keywordUrl", keywordUrl);
      formData.append("category", category);
      formData.append("imgAlt", imgAlt);
      formData.append("img", image);
      formData.append("content", content);
      formData.append("metaTitle", metaTitle);
      formData.append("metaDescription", metaDescription);
      formData.append("metaKeywords", metaKeywords);

      const response = await postBlog(token, formData);
      const { success, message } = response;

      if (success) {
        toast.success(message || "Posting Blog Success");
        setTitle("");
        setKeywordUrl("");
        setCategory("");
        setImgAlt("");
        setImage(null);
        setContent("");
        setmetaTitle("");
        setmetaDescription("");
        setmetaKeywords("");
        const fileInput = document.getElementById("imgforblog");
        fileInput.value = "";
      } else {
        toast.error(message || "Failed to post blog");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header bg-success text-white text-center">
              Create a New Blog Post
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="category" className="form-label">
                        Category <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="keywordUrl" className="form-label">
                        Keyword Url <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="keywordUrl"
                        value={keywordUrl}
                        onChange={(e) => setKeywordUrl(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="img" className="form-label">
                        Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        id="imgforblog"
                        className="form-control"
                        name="img"
                        onChange={handleImageChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="imgAlt" className="form-label">
                        Image Alt Text <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="imgAlt"
                        value={imgAlt}
                        onChange={(e) => setImgAlt(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h5>
                    Meta Tag <span>(optional)</span>
                  </h5>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="metatitle" className="form-label">
                        Meta Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="metaTitle"
                        value={metaTitle}
                        onChange={(e) => setmetaTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="metaDescription" className="form-label">
                        Meta Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="metaDescription"
                        value={metaDescription}
                        onChange={(e) => setmetaDescription(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="metaKeywords" className="form-label">
                        Meta Keywords
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="metakeyboards"
                        value={metaKeywords}
                        onChange={(e) => setmetaKeywords(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="content" className="form-label">
                    Content <span className="text-danger">*</span>
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={handleChangeContent}
                    onChange={handleChangeContent}
                  />
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={loading}>
                    {loading ? "Posting..." : "Post Blog"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostBlog;
