// src/common/api.js
import axios from "axios";

const API_BASE_URL_LIVE = process.env.REACT_APP_BASE_URL_LIVE;
const API_BASE_URL_TEST = process.env.REACT_APP_BASE_URL_TEST;

// const apiHelper = axios.create({
//   baseURL: API_BASE_URL_TEST,
// });

const apiHelper = axios.create({
  baseURL: API_BASE_URL_LIVE,
});

export default apiHelper;
