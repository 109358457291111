import React, { useEffect, useState } from "react";
import { getBlog, deleteBlog } from "../Service/blogService";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

export default function PostList() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchApiData = async () => {
    try {
      const response = await getBlog();
      if (response.success) {
        setData(response.results);
      } else {
        toast.error(response.message || "Failed to fetch blog data");
      }
    } catch (error) {
      console.error("Error fetching blog data:", error);
      toast.error("Failed to fetch blog data");
    }
  };

  const handleDelete = async (_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this blog?"
    );
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }

      const response = await deleteBlog(token, _id);
      if (response.success) {
        fetchApiData();
        toast.success(response.message || "Blog deleted successfully");
      } else {
        toast.error(response.message || "Failed to delete blog");
      }
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error("Failed to delete blog");
    }
  };

  const handleUpdate = (title) => {
    navigate(`/admin/edit-blog/${title}`);
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  const getFirstWords = (text, numWords) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.slice(0, numWords).join(" ");
  };

  return (
    <div className="container-fluid">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Sr No</th>
            <th scope="col">Date</th>
            <th scope="col">Title</th>
            <th scope="col">Category</th>
            <th scope="col">Keyword Url</th>
            <th scope="col">Image</th>
            <th scope="col">Image Alt</th>
            <th scope="col">Update</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item._id}>
              <th>{index + 1}</th>
              <td>{item.date}</td>
              <td>{getFirstWords(item.title, 5)}</td>
              <td>{getFirstWords(item.category, 5)}</td>
              <td>{getFirstWords(item.keywordUrl, 5)}</td>
              <td>
                <img
                  className="postList-img"
                  src={item.img}
                  alt={item.imgAlt}
                />
              </td>
              <td>{getFirstWords(item.imgAlt, 5)}</td>

              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleUpdate(item.title)}>
                  <FaEdit />
                </button>
              </td>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDelete(item._id)}>
                  <MdDelete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
