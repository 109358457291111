import api from "./apiHelper";

export const login = async (username, password) => {
  try {
    const response = await api.post(`api/v1/admin/login`, {
      username,
      password,
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || "Login failed");
    }
    console.log(error.message || "Login failed");
  }
};
export const logout = async (token) => {
  try {
    const response = await api.post(
      `api/v1/admin/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      console.log(error.message || "Login failed");
      throw new Error(error.response.data.message || "Logout failed");
    }
  }
};
export const verifyToken = async (token) => {
  try {
    const response = await api.post(
      `api/v1/admin/verify-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      console.log(error.message || "Login failed");
      throw new Error(error.response.data.message || "Logout failed");
    }
  }
};
