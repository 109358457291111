import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../Service/authService";
import toast from "react-hot-toast";

export default function Navbar() {
  const navigate = useNavigate();
  const loggedInUserName = localStorage.getItem("username");

  const handleLogout = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await logout(token);
      if (response.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        toast.success(response.message || "Logout Success");
        navigate("/login");
      } else {
        console.error("Logout unsuccessful:", response.error);
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-success">
        <div className="container">
          <Link className="navbar-brand" to="/admin">
            Travkom Admin
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/admin">
                  Post Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/postlist">
                  Post List
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/geturl">
                  GetUrl
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/urllist">
                  Url List
                </Link>
              </li>
            </ul>
            {loggedInUserName && (
              <div className="d-flex align-items-center">
                <span className="text-light me-3">
                  Welcome, {loggedInUserName}
                </span>
                <button
                  className="btn btn-outline-light"
                  onClick={handleLogout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
